import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError
} from "@remix-run/react";
import {LinksFunction, LoaderFunctionArgs, MetaFunction} from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";
import {GeneralErrorBoundary} from "~/component/error-boundary";
import {ReactNode, useEffect} from "react";
import {getBrowserEnv, getEnv} from "~/service/env.server";
import {initAnalyticsBrowser} from "~/helper/analytics.browser";
import useRootData from "~/hook/useRootData";
import {useMeta} from "~/hook/useMeta";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export type RootLoaderData = Awaited<ReturnType<typeof loader>>;

export async function loader({ request }: LoaderFunctionArgs) {
  return {
    DOMAIN_URL: getEnv("DOMAIN_URL"),
    browserEnv: getBrowserEnv(),
  }
}

export function Layout({ children }: { children: ReactNode }) {
  const { DOMAIN_URL} = useRootData();

  return (
    <html lang="en">
    <head>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>

      {/*<!-- Open Graph / Facebook -->*/}
      <meta property="og:image" content={`${DOMAIN_URL}/img/coach-icon.png`}/>

      {/*<!-- Twitter -->*/}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:image" content={`${DOMAIN_URL}/img/coach-icon.png`}/>

      <meta name="theme-color" content="#2e3df5"/>
      <link rel="icon" href={`${DOMAIN_URL}/favicon.ico`}/>

      <Meta/>
      <Links/>
    </head>
    <body>
    {children}
    <ScrollRestoration/>
    <Scripts/>
    </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  // TODO: check if sentry is enable
  captureRemixErrorBoundaryError(error);
  return <GeneralErrorBoundary />
};

export default function App() {
  const { browserEnv} = useLoaderData<typeof loader>();

  useEffect(() => {
    initAnalyticsBrowser(browserEnv.AMPLITUDE_BROWSER_API_KEY);
  }, []);

  return (
    <>
      <Outlet />

      {/* Make env data available on window directly */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.browserEnv = ${JSON.stringify(browserEnv)}`,
        }}
      />
    </>
  )
}

// export const meta: MetaFunction<typeof loader> = ({data, error, matches}) => {
//   return useMeta({
//     path: "/",
//     title: "Boostez votre coaching",
//     description: "Un outil 360 pour la gestion et la réservation de cours particulier en salle de sport.",
//     error,
//     matches,
//   });
// };
